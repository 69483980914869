import {useState, useEffect, useRef} from 'react';
import './Footer.scss';
import { Button, Input, Form, message, Badge } from 'antd';
import Cookies from 'universal-cookie';
import socket from '../../../pages/components/componentesInicio/Socket';
import { FileAddOutlined, SendOutlined, SmileOutlined } from '@ant-design/icons';
import ModalArchivosMsg from './ModalArchivosMsg';
import EmojiPicker from 'emoji-picker-react';


const cookies = new Cookies()

const { Item } = Form;

const Footer = () => {
  const [chatSeleccionado, setChatSeleccionado] = useState(null)
  const [canalSeleccionado, setCanalSeleccionado] = useState(null)
  const [mensaje, setMensaje] = useState('')
  const [listaArchivos, setListaArchivos] = useState ([])
  const [loadingButton, setLoadingButton] = useState(false)
  const [openModalArchivos, setOpenModalArchivos] = useState(false)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const [form] = Form.useForm();
  const resetForm = () => form.resetFields();
  const mensajeInput = useRef(null)
  
  useEffect(() => {
    socket.on("chatSeleccionado", celular => {
      // console.log('footer c',celular)
      if (chatSeleccionado !== celular) {
        setChatSeleccionado(celular)
        setMensaje('')
        resetForm()
        form.setFieldsValue({mensaje: null})
      }
      
    })

    socket.on("actualizarCanal", canal => {
      // console.log('footer',canal)
      if (canalSeleccionado !== canal) {
        setCanalSeleccionado(canal)
        setMensaje('')
        resetForm()
        form.setFieldsValue({mensaje: null})
      }
      
    })

    return () => {
      socket.off("chatSeleccionado")
      socket.off("actualizarCanal")
    }
  }, [chatSeleccionado]);

  const sendMessage = () => {
    switch (canalSeleccionado) {
      case 'whatsapp':
        if (listaArchivos.length === 0) {
          sendTextMessage()
        } else {
          sendMultiMediaMessage()
        }
        break
    
      case 'messenger':
        sendTextMessageMss()
        break
      default:
        break
    }
    
  }

  const sendTextMessageMss = async() => {
    if (mensaje === null || mensaje === '') {
      message.warning('Escribir un mensaje')
    } else {
      const body = {
        recipient:{
          id: chatSeleccionado
        },
        message:{
          text: mensaje
        }
      }

      setLoadingButton(true)

      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/sendMessageMss`,{
          method: "POST",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data => {
          if (data.error) {
            message.error(data.error)
          }
          setMensaje(null)
          resetForm()
          form.setFieldsValue({mensaje: null})
          // console.log(mensaje)
          mensajeInput.current.focus()
          setLoadingButton(false)
        }).catch(err => {
          console.log("error fetch",err)
        })
      } catch (error) {
        console.log("error sendMessage mss ",error)
      }
    }
  }

  const sendMultiMediaMessage = async() => {
    let body = []
    for (let i = 0; i < listaArchivos.length; i++) {
      const element = listaArchivos[i];
      body = [...body,{
        messaging_product: "whatsapp",
        to: chatSeleccionado,
        type: element.type,
        [element.type]: {
          link: element.link,
          caption: i === listaArchivos.length-1?mensaje:null,
          filename: element.type
        }
      }]
    }

    setLoadingButton(true)
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/sendMessage`,{
          method: "POST",
          body: JSON.stringify({body: body, id_respuesta: null}),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data => {
          if (data.error) {
            message.error(data.error)
          }
          setMensaje(null)
          resetForm()
          form.setFieldsValue({mensaje: null})
          // console.log(mensaje)
          mensajeInput.current.focus()
          setLoadingButton(false)
          setListaArchivos([])
        }).catch(err => {
          console.log("error fetch",err)
        });
      } catch (error) {
        console.log("error sendMessage ",error)
      }  
  }

  const sendTextMessage = async() => {
    if (mensaje === null || mensaje === '') {
      message.warning('Escribir un mensaje')
    } else {
      /**ESTRUCTURA DEL JSON (TEXT)*/
      const body = [{
        messaging_product: "whatsapp",
        to: chatSeleccionado,
        type: "text",
        text: {
          body: mensaje
        }
      }]

      setLoadingButton(true)
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/sendMessage`,{
          method: "POST",
          body: JSON.stringify({body: body, id_respuesta: null}),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
        .then(data => {
          if (data.error) {
            message.error(data.error)
          }
          setMensaje(null)
          resetForm()
          form.setFieldsValue({mensaje: null})
          // console.log(mensaje)
          mensajeInput.current.focus()
          setLoadingButton(false)
        }).catch(err => {
          console.log("error fetch",err)
        })
      } catch (error) {
        console.log("error sendMessage ",error)
      }
    }
  }

  const handleOpenModalArchivos = () => {
    setOpenModalArchivos(true)
  }

  const AddEmojiToText = (emoji: any) => {
    let mensaje_emoji = mensaje
    mensaje_emoji = mensaje_emoji.concat(emoji)
    setMensaje(mensaje_emoji)
    form.setFieldsValue({mensaje: mensaje_emoji})
  }

  return (<>
    <div className={'footer boxed'}>
      <div className='footer-wrap'>
        {chatSeleccionado === null?null:<>
          
          {showEmojiPicker === true?<EmojiPicker onEmojiClick={e => AddEmojiToText(e.emoji)} width={'100%'}/>:null}

          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <Form form={form} layout='vertical' onFinish={sendMessage} onKeyPress={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  form.submit();
                }
              }}>
                <div className='elem-list'>
                  <div className='col-1'>
                    {/* <Badge count={listaArchivos.length} >
                      <Button type='primary' className="m-0" onClick={e=>handleOpenModalArchivos()}
                        title="Adjuntar Links de Archivos" loading={loadingButton} icon={<FileAddOutlined />}
                      />
                    </Badge>  */}
                    <Button type='primary' title="Emoji" loading={loadingButton} icon={<SmileOutlined />}
                      onClick={e => setShowEmojiPicker(!showEmojiPicker)}
                    /> 
                  </div>  

                  <div className='col-9'>
                    <Item name="mensaje">
                      <Input.TextArea placeholder = "Escribe un mensaje aquí" ref={mensajeInput} readOnly = {loadingButton}
                        autoSize={{minRows: 1, maxRows: 4 }} value={mensaje} onChange= {e => setMensaje(e.currentTarget.value)}
                      />
                    </Item>
                  </div>

                  <div className='col-1'>
                    <Button type='primary' title="Enviar" loading={loadingButton}
                      htmlType="submit" icon={<SendOutlined />}
                    /> 
                  </div> 
                </div>
              </Form>  
            </div>
          </div>
        </>}
      </div>
    </div>
    <ModalArchivosMsg
      openModalArchivos = {openModalArchivos}
      setOpenModalArchivos = {setOpenModalArchivos}
      setListaArchivos = {setListaArchivos}
      listaArchivos = {listaArchivos}
    />
  </>);
};

export default Footer;
