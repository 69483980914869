import React, { useEffect, useState } from 'react';

import { Button, Form, Input, message } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';

import PublicLayout from '../../layout/public/Public';
import { useHistory } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useNavigateHome } from '../../utils/use-navigate-home';
import Cookies from "universal-cookie";

const cookies = new Cookies()

const { Item } = Form;

const SignIn = () => {
  const [form] = useForm();
  const navigateHome = useNavigateHome();
  const history = useHistory();
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (cookies.get('nameid_chat')) {
      window.location.href='/vertical/inicio'
      // history.push(`/`);
    }
  }, []);

  const login = () => {
    form
      .validateFields()
      .then(async () => {
        /**TRAER login Y password */
        //console.log(form.getFieldsValue())
        const usuario = form.getFieldsValue()
        // console.log(usuario.login, usuario.password)
        const body = {
          Email: usuario.login,
          Password: usuario.password
        }

        try {
          setLoading(true)
          await fetch(`${process.env.REACT_APP_HOSTNAME}/getLogin`,{
            method: "POST",
            body: JSON.stringify(body),
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(async data => {

            if (data === 'Acceso denegado' || data === '') {
              message.error('ERROR: Usuario y/o Contraseña Incorrecta') 
              setLoading(false)
            } else {
              /**EL TOKEN DEVUELVE:  ALGO.ALGO1.ALGO2, SE NECESITA EL ALGO1 */
              const substring1 = data.accessToken.substring(data.accessToken.indexOf('.')+1)
              const substring2 = substring1.substring(0, substring1.indexOf('.'))
              let loginResponse = Buffer.from(substring2, "base64").toString();

              const loginJson = JSON.parse(loginResponse)
              //PATH PARA Q SEAN ACCESIBLES DESDE CUALQUIER PAG
              // cookies.set('nameid_chat', loginJson.nameid, {path: '/'})
              // cookies.set('unique_name_chat', loginJson.unique_name, {path: '/'})
              // cookies.set('role_chat', loginJson.role, {path: '/'})
              
              await fetch(`${process.env.REACT_APP_HOSTNAME}/getPermisos`,{
                method: "POST",
                body: JSON.stringify({...loginJson, accessToken: data.accessToken}),
                headers:{
                  'Content-Type': 'application/json'
                }
              }).then(res => res.json())
              .then(async data => {
                setLoading(false)
                // console.log(data.permisos)
                /**GUARDAR LOS PERMISOS EN EL CACHÉ, EN EL COOKIE NO ALCANZA... */
                setLocaleStorage(data.permisos, loginJson/*.unique_name*/)
              })
            }

          }).catch(err => {
            console.log(err)
            message.error("Error de Conexión con el Servidor")
            setLoading(false)
          })

        } catch (error) {
          console.log(error)
        }
      })
      .catch((err) => console.log(err));
  };

  const setLocaleStorage = (permisos, /*usuario*/loginJson) => {
    // console.log(permisos)
    try {
      const permisoWasap = permisos.filter(perm => {
        return perm.palabraClave === 'WhatsApp.Acceso.Chat' && perm.permitir === true
      })
      // console.log(permisoWasap)
      if (permisoWasap.length === 0) {
        message.error('Este usuario no cuenta con permiso para acceder a esta interfaz')
      } else {
        
        cookies.set('nameid_chat', loginJson.nameid, {path: '/'})
        cookies.set('unique_name_chat', loginJson.unique_name, {path: '/'})
        cookies.set('role_chat', loginJson.role, {path: '/'})
        // console.log(permisos)
        window.localStorage.setItem('permisos', JSON.stringify(permisos))
        message.success(`Bienvenido`)
        navigateHome()  
      }
      
    } catch (error) {
      console.log(error)
    }
    
  }

  return (
    <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
      <h4 className='mt-0 mb-1' style={{color:"#ffffff"}}>Login</h4>

      <p style={{color:"#ffffff"}}>Ingresar</p>

      <Form form={form} layout='vertical' className='mb-4'>
        <Item name='login' rules={[{ required: true, message: <>Ingrese Usuario</> }]}>
          <Input placeholder='Usuario' />
        </Item>
        <Item name='password' rules={[{ required: true, message: <>Ingrese Contraseña</> }]}>
          <Input placeholder='Contraseña' type='password' />
        </Item>

        <Button
          block={false}
          type='primary'
          onClick={login}
          htmlType='submit'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
        >
          Ingresar
        </Button>
      </Form>
      <br />

    </PublicLayout>
  );
};

export default SignIn;
