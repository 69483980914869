import { CloseOutlined, SaveOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Table, Checkbox, Select } from 'antd';
import { useState, useEffect } from "react";
import { Option } from "antd/lib/mentions";
import ModalVerPlantilla from '../componentesPlantillas/ModalVerPlantilla';
import ModalContactosSeleccionados from './ModalContactosSeleccionados';

const ModalContactos = (props) => {
  const [listaContactos, setListaContactos] = useState([])
  const [listaPlantillas, setListaPlantillas] = useState([])
  const [loadingButton, setLoadingButton] = useState(false)
  const [contactosSeleccionados, setContactosSeleccionados] = useState([])
  const [inputNombre, setInputNombre] = useState('')
  const [inputCelular, setInputCelular] = useState('')
  const [inputDNI, setInputDNI] = useState('')
  const [selectPlantilla, setSelectPlantilla] = useState(undefined)
  const [openModalPlantilla, setOpenModalPlantilla] = useState(false)
  const [openModalSeleccionados, setOpenModalSeleccionados] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [plantillaSeleccionada, setPlantillaSeleccionada] = useState({
    id_template: null,
    name: null,
    encabezado: null,
    pie_pagina: null,
    tipo_encabezado: null,
    texto: null,
    variables: null
  });

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    if (props.openModalContactos === true) {
      getContactos()
      GetTemplates()
    }
  }, [props.openModalContactos])

  const getContactos = async () => {
    setLoadingTable(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetContactos`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setListaContactos([])
        } else {
          setListaContactos(data)
        }
        setLoadingTable(false)

      })
    } catch (error) {
      console.log(error)
    }
  }

  const GetTemplates = async () => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetTemplates`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setListaPlantillas([])
        } else {
          setListaPlantillas(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleCloseModal = () => {
    props.setOpenModalContactos(false)
    setListaContactos([])
    setContactosSeleccionados([])
    setListaPlantillas([])
    setInputNombre('')
    setInputCelular('')
    setInputDNI('')
    setSelectPlantilla(undefined)
  }

  const ContactoAñadido = (contacto) => {
    const contactorepet = contactosSeleccionados.filter(cont => {
      return cont.id_cliente === contacto.id_cliente
    })

    if (contactorepet.length === 0) {
      return false
    } else {
      return true
    }
  }

  const checkChange = (value, contacto) => {
    if (value === true) {
      setContactosSeleccionados([...contactosSeleccionados, contacto])
    } else {
      const lista = contactosSeleccionados.filter(cont => {
        return (cont !== contacto)
      })
      setContactosSeleccionados(lista)
    }
  }

  const columns = [
    {
      title: '',
      render: record => {
        return(
          record.whatsapp === false?null:
          <Checkbox checked={ContactoAñadido(record)}
            onChange={e => checkChange(e.target.checked, record)}
          />)
      }
    },
    {
      title: (<>
        <Input value={inputNombre} style={{width:300}}
          onChange={e=>setInputNombre(e.currentTarget.value)}
            placeholder = "NOMBRES"
        />
      </>),
      dataIndex: 'ap_nom'
    },
    {
      title: (<>
        <Input value={inputCelular} style={{width:150}}
          onChange={e=>setInputCelular(e.currentTarget.value)}
            placeholder = "CELULAR"
        />
      </>),
      dataIndex: 'celular'
    },
    {
      title: (<>
        <Input value={inputDNI} style={{width:150}}
          onChange={e=>setInputDNI(e.currentTarget.value)}
            placeholder = "DNI"
        />
      </>),
      dataIndex: 'docid'
    }
  ]

  const contactosFiltrados = () => {
    const lista = listaContactos.filter(contacto => {
      return ( contacto.ap_nom.toUpperCase().includes(inputNombre.toUpperCase()) &&
        contacto.docid.includes(inputDNI) &&
        contacto.celular.includes(inputCelular))
    })

    return lista
  }

  const sendTemplate = async () => {
    if (selectPlantilla === undefined) {
      message.error('Seleccionar Plantilla')
    } else {
      const plantilla = listaPlantillas.filter(plant => {
        return plant.id_template === selectPlantilla 
      })

      const body = { 
        contactos: contactosSeleccionados,
        plantilla: plantilla[0]
      }

      // console.log(contactosSeleccionados)
      setLoadingButton(true)
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/sendTemplate`,{
          method: "POST",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        }).then(res => res.json()) 
        .then(data => {
          setLoadingButton(false)
          handleCloseModal()
        }).catch(err => {
          console.log("error fetch",err)
          setLoadingButton(false)
        });
      } catch (error) {
        console.log("error sendTemplate ",error)
        setLoadingButton(false)
      }  
    }
  }

  const handleOpenModalPlantilla = () => {
    setOpenModalPlantilla(true)
    const plant = listaPlantillas.filter(plan => {
      return plan.id_template === selectPlantilla
    })

    setPlantillaSeleccionada(plant[0])
  }

  const handleOpenModalListaContactos = () => {
    setOpenModalSeleccionados(true)
    // console.log(contactosSeleccionados)
  }

  return(
    <>
      <Modal
        visible={props.openModalContactos}
        closable={false}
        width='80%'
        title={<>
          <h3 className='m-0'>Contactos</h3> 
          <b>Seleccionados: {contactosSeleccionados.length}</b>
          <Button loading={loadingButton} title='Ver lista seleccionada' className='ml-2'
            type='primary' icon={<EyeOutlined />} onClick={e => handleOpenModalListaContactos()}
          />
        </>}
        onCancel={handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>
            
            <Button className='bg-color-success' loading={loadingButton}
              type='primary' icon={<SaveOutlined />} onClick={e => sendTemplate()}>
              Enviar Mensaje
            </Button>
          </div>
        }
      >
        <div className='elem-list'>
          <div style={{width:200}} className="mb-2">
            <Select
              showSearch
              allowClear
              value={selectPlantilla}
              className='mr-2'
              placeholder='Seleccionar Plantilla'
              optionFilterProp='children'
              filterOption={optionFilter}
              onChange={e => setSelectPlantilla(e)}
            >
              {listaPlantillas.map(plantilla =>{
                return(
                  <Option value={plantilla.id_template} key={plantilla.id_template}>{plantilla.name}</Option>
                )
              })}
            </Select>  
            
          </div>
          {selectPlantilla === undefined?null:
            <Button loading={loadingButton} title='Ver Plantilla'
              type='primary' icon={<EyeOutlined />} onClick={e => handleOpenModalPlantilla()}
            />
          }
        </div>
        
        <Table
          // pagination={{ pageSize: 20 }}
          loading = {loadingTable}
          size='small'
          rowKey={'id_cliente'}
          columns={columns}
          dataSource={contactosFiltrados()}
        />
      </Modal>

      <ModalVerPlantilla
        openModalPlantilla = {openModalPlantilla}
        setOpenModalPlantilla = {setOpenModalPlantilla}
        plantillaSeleccionada = {plantillaSeleccionada}
        setPlantillaSeleccionada = {setPlantillaSeleccionada}
      />

      <ModalContactosSeleccionados
        openModalSeleccionados = {openModalSeleccionados}
        setOpenModalSeleccionados = {setOpenModalSeleccionados}
        contactosSeleccionados = {contactosSeleccionados}
        checkChange = {checkChange}
      />
    </>
  )
}

export default ModalContactos