import {useEffect, useState} from "react";
import { Avatar, Button, Dropdown, Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import Cookies from "universal-cookie";
import ModalAtenciones from "../../../pages/components/componentesAtenciones/ModalAtenciones";

const cookies = new Cookies()

const SettingsDropdown = () => {
  const [openModalAtenciones, setOpenModalAtenciones] = useState(false);

  const logout = () => {
    cookies.remove('nameid_chat', {path: '/'})
    cookies.remove('unique_name_chat', {path: '/'})
    cookies.remove('role_chat', {path: '/'})
  }

  const HandleOpenModalAtenciones = async() => {
    setOpenModalAtenciones(true)
  }

  const accountMenu = (
    <Menu style={{ minWidth: '180px' }}>
      <Menu.Item className='action-item' key='Atenciones' onClick={() => HandleOpenModalAtenciones()}>
        {/* <NavLink className='d-flex w-100' to='/vertical/inicio'> */}
          <div className='d-flex w-100'>
            <span className={`icon mr-3 icofont-doctor-alt`} />
            <span className='text'>Solicitudes de Atención</span>
          </div>
        {/* </NavLink> */}
      </Menu.Item>

      <Menu.Item className='action-item' key='Desconectar' onClick={() => logout()}>
        <NavLink className='d-flex w-100' to='/public/sign-in'>
          <span className={`icon mr-3 icofont-logout`} />
          <span className='text'>Cerrar Sesión</span>
        </NavLink>
      </Menu.Item>

    </Menu>
  );

  return (
    <>
      <Dropdown overlay={accountMenu} trigger={['click']} placement='bottomRight'>
        <div className='item'>
          <Avatar
            size={40}
            className='mr-1'
            src={`${window.location.origin}/content/mica.jpg`}
          />
          <span className='icofont-simple-down' style={{color:"#ffffff"}}/>
        </div>
      </Dropdown>

      {<ModalAtenciones
        openModalAtenciones = {openModalAtenciones}
        setOpenModalAtenciones = {setOpenModalAtenciones}
      />}
    </>
  );
};

export default SettingsDropdown;
