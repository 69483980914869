import { CloseOutlined, FileOutlined, PictureOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message } from 'antd';
import { useState, useEffect } from "react";
import { Option } from "antd/lib/mentions";

const ModalVerPlantilla = (props) => {
  const handleCloseModal = () => {
    props.setOpenModalPlantilla(false)
    props.setPlantillaSeleccionada({
      id_template: null,
      name: null,
      encabezado: null,
      pie_pagina: null,
      tipo_encabezado: null,
      texto: null,
      variables: null
    })
  }

  const Encabezado = () => {
    switch (props.plantillaSeleccionada.tipo_encabezado) {
      case null:
        return <div><p>Ninguno</p></div>
    
      case 'text': 
        return <div><p>{props.plantillaSeleccionada.encabezado}</p></div>

      case 'document':
        return(<>
          <h2 className='m-0'><FileOutlined/></h2>
          <b className='ml-2'>File</b>
        </>)

      case 'image':
        return(<>
          <h2 className='m-0'><PictureOutlined/></h2>
          <b className='ml-2'>Imagen</b>
        </>)

      case 'video':
        return(<>
          <h2 className='m-0'><VideoCameraOutlined/></h2>
          <b className='ml-2'>Video</b>
        </>)
      default:
        break;
    }
  }

  const BotonesTemplate = (template) => {
    switch (template.botones) {
      case 1:
        return(<Button type='primary' disabled className='mr-2'>{'{{1}}'}</Button>)
    
      case 2:
        return(<>
          <Button type='primary' disabled className='mr-2'>{'{{1}}'}</Button>
          <Button type='primary' disabled className='mr-2'>{'{{2}}'}</Button>
        </>)

      case 3:
        return(<>
          <Button type='primary' disabled className='mr-2'>{'{{1}}'}</Button>
          <Button type='primary' disabled className='mr-2'>{'{{2}}'}</Button>
          <Button type='primary' disabled className='mr-2'>{'{{3}}'}</Button>
        </>)
      default:
        break;
    }
    
  } 

  return(<>
    <Modal
        visible={props.openModalPlantilla}
        closable={false}
        width='80%'
        title={<><h3 className='m-0'>{props.plantillaSeleccionada.name}</h3></>}
        onCancel={handleCloseModal}
        footer={<div className='modal-footer d-flex justify-content-between'>
        <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
          Cerrar
        </Button>
      </div>}
      >
        <div className='row'>
          <div className='elem-list'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <div className='mb-2'><b>Encabezado</b></div>
              <div>
                {Encabezado()}
              </div>
            </div>

            <div className='col-sm-12 col-md-12 col-lg-12'>
              <div className='mb-2'><b>Texto</b></div>
              <div><p>{props.plantillaSeleccionada.texto}</p></div>
            </div>

            <div className='col-sm-12 col-md-12 col-lg-12'>
              <div className='mb-2'><b>Pie de página</b></div>
              <div>
                <p>{props.plantillaSeleccionada.pie_pagina === null?'Ninguno':props.plantillaSeleccionada.pie_pagina}</p>
              </div>
            </div>

            <div className='col-sm-12 col-md-12 col-lg-12'>
              <div className='mb-2'><b>Botones</b></div>
              <div>
                {props.plantillaSeleccionada.botones > 0?
                <>{BotonesTemplate(props.plantillaSeleccionada)}</>
                :<p>Ninguno</p>}
              </div>
            </div>
          </div>
        </div>
      </Modal>
  </>)
}

export default ModalVerPlantilla