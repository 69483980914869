import { Button, Modal, message, Table } from 'antd';
import { useState, useEffect } from "react";
import moment from "moment";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Cookies from "universal-cookie";
import socket from '../componentesInicio/Socket';

const cookies = new Cookies()

const ModalAtenciones = (props) => {
  const [loadingTable, setLoadingTable] = useState(false)
  const [listaAtenciones, setListaAtenciones] = useState([])

  useEffect(() => {
    if (props.openModalAtenciones === true) {
      GetAtenciones()
    }
  }, [props.openModalAtenciones])

  const GetAtenciones = async () => {
    setLoadingTable(true)
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetAtenciones`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setListaAtenciones([])
        } else {
          setListaAtenciones(data)
        }
        setLoadingTable(false)

      })
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: 'FECHA',
      dataIndex: 'fecha_peticion_atencion',
      render: (text: any) => ( moment.utc(text).format('DD/MM/YY HH:mm') )
    },
    {
      title: 'CELULAR',
      dataIndex: 'celular',
      render: (text: any) => ( text.substring(2, 11) )
    },
    {
      title: "APELLIDOS Y NOMBRES",
      dataIndex: 'ap_nom',
      key: 'ap_nom'
    },
    {
      title: '',
      render: record => {
        return(<>
          <Button type='primary' className="bg-color-success"
            title="Atender" 
            onClick={e => AtenderChat(record)}
            icon={<CheckOutlined />} 
          />
        </>)
      }
    }
  ]

  const HandleCloseModalAtenciones = () => {
    props.setOpenModalAtenciones(false)
    setListaAtenciones([])
  }

  const AtenderChat = async(atencion) => {
    const body = {
      ...atencion, usuario_atencion: cookies.get('nameid_chat')
    }
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AtenderChat`, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message)
        socket.emit('chatSelect', atencion.celular)
        HandleCloseModalAtenciones()
      })
    } catch (error) {
      console.log(error)
    }
  }

  return(<>
    <Modal
      visible={props.openModalAtenciones}
      closable={false}
      width='70%'
      title={<h3 className='m-0'>Atenciones Pendientes</h3>}
      onCancel={HandleCloseModalAtenciones}
      footer={
        <Button danger onClick={HandleCloseModalAtenciones} icon={<CloseOutlined />}>
          Cerrar
        </Button>
      }
    >
      <Table
        loading = {loadingTable}
        size='small'
        columns={columns}
        dataSource={listaAtenciones}
      />
    </Modal>
  </>)
}

export default ModalAtenciones