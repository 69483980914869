import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Table, Checkbox, Select } from 'antd';
import { useState, useEffect } from "react";

const ModalContactosSeleccionados = props =>{

  const handleCloseModal = () => {
    props.setOpenModalSeleccionados(false)
  }

  const columns = [
    {
      title:'',
      render: record => {
        return(<Button danger title="Quitar" onClick={e=> props.checkChange(false, record)} icon={<CloseOutlined/>}/>)
      }
    },
    {
      title: 'NOMBRES',
      dataIndex: 'ap_nom',
      key: 'ap_nom'
    },
    {
      title: 'CELULAR',
      dataIndex: 'celular',
      key: 'celular'
    },
    {
      title: 'DNI',
      dataIndex: 'docid',
      key: 'docid'
    }
  ]

  return(<>
    <Modal
      visible={props.openModalSeleccionados}
      closable={false}
      width='80%'
      title={<><h3 className='m-0'>Contactos Seleccionados</h3> </>}
      onCancel={handleCloseModal}
      footer={<div className='modal-footer d-flex justify-content-between'>
        <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
          Cerrar
        </Button>
      </div>}
    >
      <Table
        // pagination={{ pageSize: 20 }}
        size='small'
        rowKey={'id_cliente'}
        columns={columns}
        dataSource={props.contactosSeleccionados}
      />
    </Modal>
  </>)
}

export default ModalContactosSeleccionados