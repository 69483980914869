export const DEFAULT_SETTINGS = {
  boxed: false, //true,
  loaded: false,
  layout: 'vertical',// horizontal | vertical
  sidebarBg: '#2c343b',// string
  sidebarColor: '#ffffff',// string
  sidebarOpened: false,// boolean
  topbarBg: '#2c343b',// string
  topbarColor: '#1F2022'// string
};
