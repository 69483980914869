import { CloseOutlined, SaveOutlined, EyeOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Modal, message, Table, Checkbox, Select } from 'antd';
import { useState, useEffect, useRef } from "react";
import { Option } from "antd/lib/mentions";

const ModalArchivosMsg = props => {
  const [listaArchivos, setListaArchivos] = useState ([{
    type: undefined,
    link: null
  }])
  const [file64, setFile64] = useState(null)
  const input = useRef<HTMLInputElement>(null);
  const handleClick = () => input.current.click();

  useEffect(()=>{
    if (props.openModalArchivos === true) {
      if (props.listaArchivos.length === 0) {
        setListaArchivos([{
          type: undefined,
          link: null
        }])
      }
    }
  }, [props.openModalArchivos])

  const optionFilter = (input, option) =>
  option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleCloseModal = () => {
    props.setOpenModalArchivos(false)
  }

  const GuardarArchivos = () => {
    if (listaArchivos.length === 0) {
      props.setListaArchivos([...listaArchivos])
    } else {
      const validarItems = listaArchivos.filter(item => {
        return item.type === undefined || item.link === null || item.link === ''
      })

      if (validarItems.length > 0) {
        message.warn('Hay campos vacios')
      } else {
        props.setListaArchivos([...listaArchivos])
        handleCloseModal()
      }
    }
  }

  const AddArchivo = () => {
    setListaArchivos([...listaArchivos,{
      type: undefined,
      link: null
    }])
  }

  const RemoveArchivo = (archivo) => {
    const listaItems = listaArchivos.filter(detalle => {
      return(detalle !== archivo)
    })

    setListaArchivos(listaItems)
  }

  const archivoChange = (value, campo, item) => {
    const listaItems = listaArchivos
    const key = listaItems.indexOf(item)

    listaItems[key][campo] = value
    setListaArchivos([...listaItems])
  }

  const columns = [
    {
      title: (
        <>
          <Button type='primary' onClick={e => AddArchivo()} title="Agregar Item" icon={<PlusOutlined/>}/>
        </>
      ),
      render: record => {
        return(
          <>
            <Button danger title="Quitar" onClick={e=> RemoveArchivo(record)} icon={<DeleteOutlined/>}/>
          </>
        )
      }
    },
    {
      title: 'TIPO',
      key: 'type',
      render: record => {
        return(<Select
          value={record.type}
          className='mb-lg-0'
          optionFilterProp='children'
          filterOption={optionFilter}
          onChange = {e => archivoChange(e, 'type', record)}
        >
          <Option value='image' key='image'>Imagen</Option>
          <Option value='document' key='document'>Documento</Option>
          <Option value='video' key='video'>Video</Option>
        </Select>)
      }
    },
    {
      title: 'LINK',
      key: 'link',
      render: record => {
        return(
          <Input value={record.link} placeholder='URL del archivo' 
            onChange={e => archivoChange(e.currentTarget.value, 'link', record)}
          />
        )
      }
    }
  ] 

  return(<>
    <Modal
      visible={props.openModalArchivos}
      closable={false}
      width='80%'
      title={<><h3 className='m-0'>Archivos</h3></>}
      onCancel={handleCloseModal}
      footer={<div className='modal-footer d-flex justify-content-between'>
        <Button danger onClick={handleCloseModal} icon={<CloseOutlined />}>
          Cerrar
        </Button>
        
        <Button className='bg-color-success'
          type='primary' icon={<SaveOutlined />} onClick={e => GuardarArchivos()}>
          Guardar
        </Button>
      </div>}
    >
      <div className='col-sm-12 col-md-12 col-lg-12'>
        <Table 
          pagination={false} 
          columns={columns}
          dataSource={listaArchivos}
          size="small"
        />                  
      </div>
    </Modal>
  </>)
}

export default ModalArchivosMsg