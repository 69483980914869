import { useEffect, useState } from 'react';
import BaseLayout from '../base/BaseLayout';
import Logo from '../components/logo/Logo';
import Navbar from '../components/navbar/Navbar';
import LogoSvg from './../../assets/img/logoLimatambo.png'
import NavLoader from '../components/navbar/NavLoader';
import ActionsVertical from '../components/actions/ActionsVertical';
import { toggleSidebar } from '../../redux/settings/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../interfaces/app-state';
import './Vertical.scss';
import moment from 'moment'
import socket from '../../pages/components/componentesInicio/Socket';
import { Avatar, Button, Input, Badge, message, Select } from 'antd';
import { CheckOutlined, ExclamationOutlined, MessageOutlined, ExclamationCircleOutlined,
  SearchOutlined, WarningOutlined, WhatsAppOutlined, FacebookFilled } from '@ant-design/icons';
import ModalContactos from '../../pages/components/componentesContactos/ModalContactos';
import Actions from '../components/actions/Actions';
import Cookies from "universal-cookie";
import { Option } from 'antd/lib/mentions';

const cookies = new Cookies()

type Props = {
  children: any;
};

const VerticalLayout = ({ children }: Props) => {
  const dispatch = useDispatch();

  const settings = useSelector((state: IAppState) => state.settings);
  const pageData = useSelector((state: IAppState) => state.pageData);

  const onSidebarToggle = () => dispatch(toggleSidebar());

  const [chats, setChats] = useState([]);
  const [inputBuscar, setInputBuscar] = useState('');
  const [selectCanal, setSelectCanal] = useState(undefined);
  const [chatSelected, setChatSelected] = useState({
    celular: null,
    ap_nom: null
  });
  const [canalSeleccionado, setCanalSeleccionado] = useState(null)
  const [openModalContactos, setOpenModalContactos] = useState(false);
  const [cantidadChats, setCantidadChats] = useState(10);

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    GetChatsList()

    socket.on("actualizarChatCabecera", contacto => {
      // console.log(contacto)
      setChatSelected({
        celular: contacto.celular?contacto.celular:contacto.id,
        ap_nom: contacto.ap_nom?contacto.ap_nom: contacto.first_name.concat(` ${contacto.last_name}`)
      })
    })

    socket.on("actualizarCanalCabecera", canal => {
      // console.log(canal)
      setCanalSeleccionado(canal)
    })
  }, []);

  useEffect(() => {
    socket.on("getChatsList", newlistachats => {
      // console.log(newlistachats)
      setChats([...newlistachats])
    })

    socket.on('actualizarChat', msg => {
      // console.log('actualizarChat', msg)
      const listaMsgs = chats
      const msgViejo = chats.filter(mensaje => {
        return mensaje.id === msg.id
      })

      if (msgViejo.length > 0) {
        const key = listaMsgs.indexOf(msgViejo[0])
        listaMsgs[key] = msg
        setChats([...listaMsgs])  
      }
    })

    return () => {
      socket.off("getChatsList")
      socket.off("actualizarChat")
    }
  }, [chats])

  const GetChatsList = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetChatsList`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          setChats([])
        } else {
          setChats(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const iconoCanal = (chat) => {
    switch (chat.canal) {
      case 'whatsapp':
        return(<WhatsAppOutlined className='mr-1' style={{color: '#29E30C'}}/>)
    
      case 'messenger':
        return(<FacebookFilled className='mr-1' style={{color: '#0C7EE3'}}/>)

      default:
        break;
    }
  }

  const nav = (
    <Navbar
      boxed={settings.boxed}
      color={settings.topbarColor}
      background={settings.topbarBg}
      orientation='horizontal'
    >
      <button className='no-style navbar-toggle d-lg-none' onClick={onSidebarToggle}>
        <span />
        <span />
        <span />
      </button>

      {/* <Actions /> */}
      {chatSelected.celular !== null?
      <div className='actions'>
        <b title={`${chatSelected.ap_nom} - ${chatSelected.celular}`} style={{color:"#ffffff"}}>
          {iconoCanal({canal:canalSeleccionado})}{chatSelected.ap_nom === ' '?chatSelected.celular:chatSelected.ap_nom}
        </b>
        <div className='item'>
          {/* <Badge count={iconoCanal({canal:canalSeleccionado})}> */}
            <Avatar size={40} className='mr-1' src={`${window.location.origin}/content/user-40-2.jpg`}
            />
          {/* </Badge> */}
        </div>
      </div>
      :null}
      
      <NavLoader loaded={pageData.loaded} type={'top-bar'} />
    </Navbar>
  );

  const fechaMsg = (fecha_mensaje) => {
    if (moment.utc(fecha_mensaje).format('YYYY-MM-DD') === moment.utc(new Date()).format("YYYY-MM-DD")) {
      return moment.utc(fecha_mensaje).format('HH:mm')
    } else {
      if (moment.utc(fecha_mensaje).format('YYYY-MM-DD') === moment.utc(new Date()).subtract(1, 'days').format("YYYY-MM-DD")) {
        return 'Ayer'
      } else {
        return moment.utc(fecha_mensaje).format('DD/MM/YYYY')
      }
    }
  }

  const SeleccionarChat = (chat) => {
    // console.log(chat)
    switch (chat.canal) {
      case 'whatsapp':
        socket.emit('chatSelect', chat.celular)
        // setChatSelected(chat)
        GetCountAtencionesByCelular(chat)
        if (chat.status !== 'read' && chat.origen_clinica === false) {
          MessageLeido(chat)  
        }
        break;
    
      case 'messenger':
        // console.log(chat)
        socket.emit('chatSelectMss', chat.id_persona)
        // setChatSelected(chat)
        break;

      default:
        break;
    }
    
  }

  const GetCountAtencionesByCelular = async(chat) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/GetCountAtencionesByCelular/${chat.celular}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (!data.message) {
          if (data[0].cantidad_atenciones > 0) {
            AtenderChat(chat)
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const AtenderChat = async(chat) => {
    const body = {
      ...chat, usuario_atencion: cookies.get('nameid_chat')
    }
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/AtenderChat`, {
        method: "PUT",
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data =>{
        message.success(data.message)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const MessageLeido = async(chat) => {
    /**ESTRUCTURA DEL JSON PARA MSG LEIDO */
    const body = {
      messaging_product: "whatsapp",
      status: "read",
      message_id: chat.id
    }
    
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/MessageLeido`,{
        method: "POST",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
      }).catch(err => {
        console.log("error fetch",err)
      });
    } catch (error) {
      console.log("error try catch",error)
    }
  }

  const chatsFiltrados = () => {
    const lista = chats.filter(chat => {
      return( 
        (selectCanal === undefined? chat.canal !== selectCanal: chat.canal === selectCanal) &&
        (chat.celular.includes(inputBuscar) || chat.ap_nom.toUpperCase().includes(inputBuscar.toUpperCase()))
      )
    })

    return lista.slice(0, cantidadChats)
  }

  const iconoMsgStatus = (chat) => {
    if (chat.origen_clinica === true) {
      switch (chat.status) {
        case 'failed':
          return(<ExclamationOutlined className='ml-2'/>)
      
        case 'sent':
          return(<CheckOutlined style={{color: 'black'}} className='ml-2'/>)

        case 'delivered':
          return(<>
            <CheckOutlined style={{color: 'black'}} className='ml-2'/>
            <CheckOutlined style={{color: 'black'}}/>
          </>)

        case 'read':
          return(<>
            <CheckOutlined style={{color: '#00CADE'}} className='ml-2'/>
            <CheckOutlined style={{color: '#00CADE'}}/>
          </>)

        case 'deleted':
          return(<WarningOutlined className='ml-2'/>)

        default:
          return(null)
      }  
    } else {
      if (chat.status === null) {
        return <Badge count={<ExclamationCircleOutlined title='No leído' style={{color: '#52c41a'}}/>} />
      } else {
        return null
      }
    }
    
  }

  const HandleOpenModalContactos = () => {
    setOpenModalContactos(true)
  }

  const getMensaje = (chat) => {
    // console.log(chat)
    switch (chat.tipo) {
      case 'text': case 'interactive':
        return(
          chat.mensaje === null?null:
          <b className='ml-2' title={chat.mensaje}>{chat.mensaje.length > 30? chat.mensaje.substring(0, 30).concat('...'): chat.mensaje}</b>
        )
      
      case 'template':
        const encabezado = chat.encabezado === null?'':chat.encabezado
        if (chat.id_media === null) {
          return(
            <b className='ml-2' title={encabezado.concat(' ',chat.mensaje)}>
              {encabezado.concat(' ',chat.mensaje).length > 30? encabezado.concat(' ',chat.mensaje).substring(0, 30).concat('...'): 
              encabezado.concat(' ',chat.mensaje)}
            </b>
          )
        } else {
          return(<b className='ml-2' title={encabezado.concat(' ',chat.mensaje)}>{chat.tipo_encabezado}</b>)
        }
      default:
        return(
          chat.mensaje === null?<b className='ml-2'>{chat.tipo}</b>:
          <b className='ml-2' title={chat.mensaje}>{chat.mensaje.length > 30? chat.mensaje.substring(0, 30).concat('...'): chat.mensaje}</b>
        )
    }
  }

  const Mostrar10ChatsMas = () => {
    setCantidadChats(cantidadChats + 10)
  }

  const sideNav = (
    <Navbar
      onClickOutside={onSidebarToggle}
      opened={settings.sidebarOpened}
      color={settings.sidebarColor}
      background={settings.sidebarBg}
      orientation='vertical'
    >
      {/* <Logo src={LogoSvg} /> */}
      <div className='mt-2 elem-list d-flex justify-content-between'>
        <div><ActionsVertical /> </div>
        {/* <div>
          <Button type='primary' className="mr-4" title="Iniciar Conversación" 
          onClick={e => HandleOpenModalContactos()} icon={<MessageOutlined />}/>
        </div> */}
      </div>

      <table className='mt-3'>
        <tbody>
          <td colSpan={2}>
            <div className='row'>
              <div className='col-9 col-9 col-9'>
                <Input value={inputBuscar} onChange={e => setInputBuscar(e.currentTarget.value)} className='mb-2'
                    placeholder='Buscar chat' prefix={<SearchOutlined />} allowClear
                  />
              </div>

              <div className='col-3 col-3 col-3'>
                <Select
                  allowClear
                  defaultValue={selectCanal}
                  className='mb-lg-0'
                  placeholder=''
                  optionFilterProp='children'
                  filterOption={optionFilter}
                  onChange={(e)=>setSelectCanal(e)}
                >
                  <Option value='whatsapp' key='whatsapp'><WhatsAppOutlined className='mr-1' style={{color: '#29E30C'}}/></Option>
                  <Option value='messenger' key='messenger'><FacebookFilled className='mr-1' style={{color: '#0C7EE3'}}/></Option>
                </Select>
              </div>
            </div>
          </td>
          {chatsFiltrados().map(chat => {
            // console.log(chat.celular)
            // console.log(chatSelected)
            return(<>
              <tr className='table table' onClick={e => SeleccionarChat(chat)} 
                style={{background: canalSeleccionado === chat.canal && 
                  (chat.celular === '51'.concat(chatSelected.celular) || chat.id_persona === chatSelected.celular)?'#5D5D5D':null
                }}
              >
                <td style={{textAlign: 'left'}} title={`${chat.ap_nom}-${chat.celular.substring(2, 11)}`}>
                  {iconoCanal(chat)}{chat.ap_nom === ''?chat.celular.substring(2, 11):chat.ap_nom.substring(0, 20).concat('...')}
                </td>
                <td style={{textAlign: 'right', fontSize: 12}}>{fechaMsg(chat.fecha_mensaje)}</td>
              </tr>

              <td colSpan={2} onClick={e => SeleccionarChat(chat)} 
                style={{background: canalSeleccionado === chat.canal && 
                  (chat.celular === '51'.concat(chatSelected.celular) || chat.id_persona === chatSelected.celular)?'#5D5D5D':null}}
              >
                <div className='elem-list mr-2 ml-2 d-flex justify-content-between'>
                  {getMensaje(chat)}
                  <div>{iconoMsgStatus(chat)}</div>
                  
                </div>
              </td>
            </>)
          })}
        </tbody>
      </table>

      {chatsFiltrados().length < chats.length? 
        <Button onClick={e => Mostrar10ChatsMas()}>Mostrar +</Button>
      :null}

      <NavLoader loaded={pageData.loaded} type={'nav-bar'} />
    </Navbar>
  );

  return (
    <>
      <BaseLayout orientation='vertical' nav={nav} sideNav={sideNav} backgroundColor={canalSeleccionado === 'whatsapp'? '#F3E8D6': '#FFFFFF'} >
        {children}
      </BaseLayout>

      {<ModalContactos 
        openModalContactos = {openModalContactos}
        setOpenModalContactos = {setOpenModalContactos}
      />}
    </>
  );
};

export default VerticalLayout;
